import { Badge, Button, Col, Row } from "antd";
import React, { useState, useRef } from "react";
import { Filter } from "react-iconly";
import ModalDrawer from "../content/modal-drawer";

const FilterTable = ({ isFiltered = false, setFilterValue, children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => {
    setIsModalVisible(false);
    if (formRef.current && formRef.current.resetForm) {
      formRef.current.resetForm();
    }
  };

  const handleFilter = () => {
    if (formRef.current && formRef.current.submit) {
      formRef.current.submit();
      setIsModalVisible(false); // Close modal after submitting
    }
  };

  const handleReset = () => {
    if (formRef.current && formRef.current.hardResetForm) {
      formRef.current.hardResetForm();
      setIsModalVisible(false);
      setFilterValue(null);
    }
  };

  return (
    <>
      <Button
        data-testid="btn-table-filter"
        icon={
          isFiltered ? (
            <Badge dot>
              <Filter size={16} />
            </Badge>
          ) : (
            <Filter size={16} />
          )
        }
        onClick={() => setIsModalVisible(true)}
      />
      <ModalDrawer
        title={
          <>
            <h4>Filter</h4>
          </>
        }
        visible={isModalVisible}
        onClose={handleClose}
        destroyOnClose={true}
        footer={
          <>
            <Row gutter={[8, 8]} justify={"space-between"}>
              <Col>
                {isFiltered && (
                  <Button onClick={handleReset} danger>
                    Reset
                  </Button>
                )}
              </Col>
              <Row gutter={[8, 8]}>
                <Col>
                  <Button onClick={() => setIsModalVisible(false)}>
                    Batal
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={handleFilter}
                    type="primary"
                    htmlType="submit"
                    data-testid="btn-submit"
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
            </Row>
          </>
        }
      >
        {React.cloneElement(children, { ref: formRef })}
      </ModalDrawer>
    </>
  );
};

export default FilterTable;
