import React, { useEffect, useState } from "react";

import { Col, Row } from "antd";
import {
  Calendar,
  Category,
  Chat,
  Folder,
  Home,
  Paper,
  Setting,
  TwoUsers,
  User,
} from "react-iconly";
import { Link } from "react-router-dom";

export default function BottomMenu(props) {
  const { active } = props;

  return (
    <Row className="hp-app-menu-layout hp-m-0 hp-bg-color-black-10 hp-bg-color-dark-90">
      <Col flex="1" className="hp-text-center">
        <Link
          to="/dashboard"
          className={
            active === "home"
              ? "hp-app-menu hp-text-color-primary-1 hp-text-color-dark-primary-2-2"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <Home set="curved" />
          <span className="hp-d-block">Beranda</span>
        </Link>
      </Col>
      <Col flex="1" className="hp-text-center">
        <Link
          to="/system/data"
          className={
            active === "system"
              ? "hp-app-menu hp-text-color-primary-1 hp-text-color-dark-primary-2-2"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <Category set="curved" />
          <span className="hp-d-block">Sistem</span>
        </Link>
      </Col>
      <Col flex="1" className="hp-text-center">
        <Link
          to="/settings"
          className={
            active === "settings"
              ? "hp-app-menu hp-text-color-primary-1 hp-text-color-dark-primary-2-2"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <Setting set="curved" />
          <span className="hp-d-block">Pengaturan</span>
        </Link>
      </Col>

      <Col flex="1" className="hp-text-center">
        <Link
          to="/profile/my-profile"
          className={
            active === "profile"
              ? "hp-app-menu hp-text-color-primary-1 hp-text-color-dark-primary-2-2"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <User set="curved" />
          <span className="hp-d-block">Profil</span>
        </Link>
      </Col>
    </Row>
  );
}
