import {
  Category,
  Chat,
  Folder,
  Message,
  Password,
  People,
  User,
  Work,
} from "react-iconly";

const pages = [
  {
    header: "UTAMA",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
    access: null,
  },
  {
    header: "LAINNYA",
  },
  {
    id: "system",
    title: "Sistem",
    icon: <Work set="curved" className="remix-icon" />,
    navLink: "/system/data",
    access: ["system-index"],
  },
  {
    id: "user",
    title: "Manajemen User",
    icon: <People set="curved" className="remix-icon" />,
    navLink: "/user/data",
    access: ["user-index"],
  },
  {
    id: "role",
    title: "Role & Permission",
    icon: <Password set="curved" className="remix-icon" />,
    navLink: "/role/data",
    access: ["role-index"],
  },
  {
    id: "profile",
    title: "Profile",
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/profile/my-profile",
    access: null,
  },
];

export default pages;
