const themeConfig = {
  appname: "Data Center",
  company: "PT Eka Akar Jati",
  year: "2024",
  version: "1.0",
  theme: localStorage.getItem("theme") === "dark" ? "dark" : "light",
  contentWidth: "full",
  sidebarCollapsed:
    localStorage.getItem("sidebar-collapsed") === "true" ? true : false,
  sidebarCollapseButton: true,
  layout: "VerticalLayout",
  navigationFull: false,
  navigationBg: false,
  direction: "ltr",
  language: "id",
  whatsapp: "6281234567890",
};

export default themeConfig;
