import React, { useEffect, useState } from "react";

import { Card, Row, Col, Form, Select } from "antd";
import Chart from "react-apexcharts";

export default function PotensiPencairanHolding(props) {
  const { rangeValue } = props;

  const [form] = Form.useForm();

  const [dataOverview, setDataOverview] = useState({
    series: [
      {
        name: "Pencairan",
        data: [100, 75, 65, 60, 56, 48],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 260,
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          borderRadius: 4,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [
          "Siber",
          "Armas",
          "Simantap",
          "Monstrac",
          "Siar",
          "Samporna",
        ],
        labels: {
          style: {
            colors: [
              "#00622F",
              "#015AFC",
              "#0B3C94",
              "#C52027",
              "#562B7D",
              "#8189FF",
            ],
            fontSize: "12px",
          },
        },
      },
      colors: [
        "#00622F",
        "#015AFC",
        "#0B3C94",
        "#C52027",
        "#562B7D",
        "#8189FF",
      ],
      yaxis: {
        title: {
          text: "Value",
        },
      },
      legend: {
        show: false,
      },
    },
  });

  return (
    <Card className="hp-mb-24">
      <h5>Potensi Pencairan Holding</h5>
      <div id="chart">
        <Chart
          options={dataOverview.options}
          series={dataOverview.series}
          type="bar"
          height="260px"
        />
      </div>
    </Card>
  );
}
