import React, { useEffect, useState } from "react";

import { Row, Col, Button, Card } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";
import { Link } from "react-router-dom";
import { RiArrowLeftLine, RiHeadphoneLine, RiUserLine } from "react-icons/ri";
import BottomMenu from "../../../layout/components/bottom-menu";
import {
  Chart,
  Paper,
  People,
  Ticket,
  TicketStar,
  TimeCircle,
  Wallet,
} from "react-iconly";
import { get } from "../../../utils/service";
import { API_URL } from "../../../api/config";

import cardImg from "../../../assets/images/dashboard/analytics-payment-bg.svg";
import cardImgDark from "../../../assets/images/dashboard/analytics-payment-bg-dark.png";
import { useSelector } from "react-redux";
import PotensiPencairanHolding from "./component/potensi-pencairan";
import PencairanHolding from "./component/pencairan";
import FilterTable from "../../../layout/components/button-filter";
import FormFilter from "./component/filter";

export default function Pages() {
  const theme = useSelector((state) => state.customise.theme);

  const [dataSource, setDataSource] = useState([]);
  const [filterValue, setFilterValue] = useState(null);

  const getData = async () => {
    const res = await get(API_URL.stats);
    if (res.status === 200) {
      setDataSource(res.data.data);
    }
  };

  useEffect(() => {
    // getData();
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  // Device Size Handling
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  const handleFilter = () => {
    //
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard">
        <Row>
          <Col>
            <FilterTable
              isFiltered={filterValue !== null ? true : false}
              setFilterValue={(value) => setFilterValue(value)}
            >
              <FormFilter onFinish={handleFilter} values={filterValue} />
            </FilterTable>
          </Col>
        </Row>
      </PageTitle>

      <div className="hp-content-main">
        <Row gutter={[16, 16]} className="hp-mb-24">
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-warning-4 hp-bg-dark-warning hp-text-color-warning-1 icon">
                  <Paper size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Data Potensi Cair
                  </p>
                  <div className="hp-d-flex hp-align-items-center">
                    <h4 className="hp-mb-0">100</h4>
                    <h6 className="hp-mb-0 hp-text-color-grey-1 hp-ml-4">
                      NOA
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-orange-4 hp-bg-dark-orange hp-text-color-orange-1 icon">
                  <Wallet size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Potensi Cair
                  </p>
                  <h4 className="hp-mb-0">Rp 1.000.000.000</h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-warning-4 hp-bg-dark-warning hp-text-color-warning-1 icon">
                  <Wallet size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Pending Cair
                  </p>
                  <h4 className="hp-mb-0">Rp 1.000.000.000</h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={12}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-success-4 hp-bg-dark-success hp-text-color-success-1 icon">
                  <Chart size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Data Cair
                  </p>
                  <div className="hp-d-flex hp-align-items-center">
                    <h4 className="hp-mb-0">100</h4>
                    <h6 className="hp-mb-0 hp-text-color-grey-1 hp-ml-4">
                      NOA
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={12}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-success-4 hp-bg-dark-success hp-text-color-success-1 icon">
                  <Wallet size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Cair
                  </p>
                  <h4 className="hp-mb-0">Rp 1.000.000.000</h4>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <PotensiPencairanHolding />
        <PencairanHolding />
        {/* <Card
          className="hp-border-color-black-40 hp-card-1 hp-upgradePlanCardOne hp-upgradePlanCardOne-bg"
          style={{
            backgroundImage: `url(${theme == "dark" ? cardImgDark : cardImg})`,
            backgroundSize: "cover",
            backgroundPosition: "right !important",
          }}
        >
          <Row align="middle" className="hp-mt-8">
            <Col span={24} className="hp-mb-4">
              <Row align="middle" justify="space-between">
                <Col flex="1">
                  <h4 className="hp-mb-8">
                    Selamat Datang di Datacenter Ekaakarjati
                  </h4>

                  <p className=" hp-mb-0">
                    Lihat update terbaru dari masing-masing sistem
                  </p>
                </Col>

                <Link to="/system/data">
                  <Button className="hp-float-right hp-mt-xs-16 hp-text-color-primary-1 hp-bg-dark-primary-1 hp-border-color-dark-primary-1 hp-text-color-dark-0">
                    Lihat System
                  </Button>
                </Link>
              </Row>
            </Col>
          </Row>
        </Card> */}
      </div>

      <BottomMenu active="home" />
    </>
  );
}
